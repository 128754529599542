import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class CoworkingCampusOAVacationManagementModel extends HTTP {
    getDictList (data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.OPERATE_COMMON_GETDICTLIST+ `?dictKey=${data}`,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getVacationSearchList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_VACATION_SEARCH_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

   saveSchoolVacation(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_SCHOOL_VACATION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    
    getSchoolVacationInfo(id) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.OA_SCHOOL_VACATION_INFO+id,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    deleteSchoolVacation(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_SCHOOL_VACATION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    getUndeletedList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_UNDELETED_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    getVacationDuration (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LEAVE_HOURS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    //手动销假时获取请假时长
    getVacationDurationHandleCancel (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LEAVE_HOURS_HANDLE_CANCEL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    //手动销假
    handCancelLeave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.HANDLE_CANCEL_LEAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 获取已选择人
    getListByFormIds (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LIST_BY_FORMIDS1,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 修改假期状态
    updateVacationStatus (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.UPDATE_SCHOOL_VACATION_STATUS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
}

export { CoworkingCampusOAVacationManagementModel }
